import config from "./config";
import React from "react";
import { createStore, applyMiddleware, combineReducers } from "redux";
import {
  botUserSessionReducer,
  authClientWrapper,
} from "@pypestream/bot-user-session";
import { Provider } from "react-redux";
import thunkMiddleware from "redux-thunk";
import designerReducer from "Designer/Store/reducer";
import managerReducer from "Manager/Store/reducer";
import designProReducer from "./Solution/store";
import activityTracker from "activityTracker";
import PropTypes from "prop-types";
import { setExpiredAuthenticationCallback } from "./bot-manager-api";

const OktaAuthConfig = config.OktaAuthConfig;
authClientWrapper.setAuthConfiguration(OktaAuthConfig);

const rootReducer = combineReducers({
  manager: managerReducer,
  botUserSession: botUserSessionReducer,
  designer: designerReducer,
  ...designProReducer,
});

export const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));

setExpiredAuthenticationCallback(() => {
  const isLogIn = !!window.sessionStorage.getItem("global_accessToken");
  const slash = "/";
  const unauthorized = "unauthorized";
  const isConfigEndWithSlash = config.homepage.endsWith(slash);
  const unauthorizedUrl = isConfigEndWithSlash
    ? `${config.homepage}${unauthorized}`
    : `${config.homepage}${slash}${unauthorized}`;

  if (isLogIn) {
    window.location.pathname = unauthorizedUrl;
  }
});

activityTracker.init();

export const StoreProvider = ({ children }) => (
  <Provider store={store}>{children}</Provider>
);

StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
