import { BOT_USER_SESSION_LOGOUT } from "@pypestream/bot-user-session";
import * as t from "./actionTypes";
import { EDGE_STATE, NODE_STATE, PRESERVE_NODE_NUMBERS } from "../lib/defs";
import { CLEAR_DESIGNER_DATA } from "./actionTypes";
import { getEdgeLineV2 } from "../lib/utills";
import { getDescenders, getViewableAccessor } from "../lib/botUtills";
import NodeContainer from "../lib/nodesContainer";

let saveTimeout = null;
const myRandomTabId = Math.random();
const saveBot = (bot, state) => {
  if (saveTimeout) clearTimeout(saveTimeout);
  saveTimeout = setTimeout(
    () => {
      const nodes = !bot
        ? []
        : bot.mapNodes((node) => ({
            data: node.data,
            meta: {
              x: node.meta.x,
              y: node.meta.y,
              width: node.meta.width,
              height: node.meta.height,
              NLPKeywords: node.meta.NLPKeywords,
              isGroup: node.meta.isGroup,
              groupNumber: node.meta.groupNumber,
              hideEdges: node.meta.hideEdges,
              readOnly: node.meta.readOnly,
              childNodes: node.meta.childNodes,
            },
          }));
      window.localStorage.setItem(
        `bot:${state.customerId}:${state.botId}`,
        JSON.stringify({ tabId: myRandomTabId, nodes })
      );
      window.console.log("Solution Saved");
    },
    bot ? 1500 : 100
  );
};

const getInitialState = () => ({
  botId: null,
  customerId: null,
  nodes: null,
  edges: null,
  selectedNodeId: undefined,
  selectedEmbedIndex: -1,
  selectedEdge: null,
  zoomLevel: 1,
  zoomLevelMouseX: undefined,
  zoomLevelMouseY: undefined,
  alignToRoot: false,
  globalVariables: [],
  wasBotModified: false,
  uploadingBot: false,
  selectedGroup: PRESERVE_NODE_NUMBERS.RootGroup,
  leftSideDrawerOpen: true,
  rightSideDrawerOpen: false,
  clipboard: [],
  clipType: "",
  parentNodes: [],
});

function getEdgeState(source, target, selectedNodeId) {
  let edgeState =
    selectedNodeId >= 0
      ? EDGE_STATE.EDGE_STATE_NOT_CONNECTED
      : EDGE_STATE.EDGE_STATE_DEFAULT;
  if (source === selectedNodeId) {
    edgeState = EDGE_STATE.EDGE_STATE_OUTGOING;
  } else if (target === selectedNodeId) {
    edgeState = EDGE_STATE.EDGE_STATE_INCOMING;
  }
  return edgeState;
}

function getEdgesToShow(allNodes, selectedNodeId, selectedGroup) {
  const edgesSet = {};

  if (allNodes) {
    allNodes.forEachNode((sourceNode) => {
      const presentingSourceNode = getViewableAccessor(
        sourceNode,
        allNodes,
        selectedGroup,
        true
      );
      if (presentingSourceNode) {
        sourceNode.data.next_nodes.forEach((targetNode) => {
          const presentingTargetNode = getViewableAccessor(
            allNodes.getNodeRead(targetNode),
            allNodes,
            selectedGroup,
            true
          );
          if (
            presentingTargetNode &&
            presentingSourceNode.data.number !==
              presentingTargetNode.data.number
          ) {
            const id = `e${presentingSourceNode.data.number}-${presentingTargetNode.data.number}`;

            const { polyline, arrow } = getEdgeLineV2(
              presentingSourceNode.meta,
              presentingTargetNode.meta
            );
            if (!edgesSet[id]) {
              edgesSet[id] = {
                key: id,
                polyline,
                arrow,
                edgeState: getEdgeState(
                  presentingSourceNode.data.number,
                  presentingTargetNode.data.number,
                  selectedNodeId
                ),
              };
            }
          }
        });
      }
    });
  }
  return edgesSet;
}

function setNodeState(allNodes, selectedNodeId, selectedGroup) {
  //reset
  allNodes.forEachNode((node) => {
    node.meta.state =
      selectedNodeId >= 0
        ? NODE_STATE.NODE_STATE_NOT_CONNECTED
        : NODE_STATE.NODE_STATE_DEFAULT;
  });

  if (selectedNodeId >= 0) {
    const selectedNode = allNodes.getNodeRead(selectedNodeId);
    if (selectedNode) {
      //getting all descenders
      const descenders = [];
      getDescenders(selectedNode, allNodes, descenders);

      // were the descenders are the source
      descenders.forEach((node) => {
        node.data.next_nodes.forEach((targetNodeNumber) => {
          const targetNode = allNodes.getNodeRead(targetNodeNumber);
          if (targetNode) {
            const viewableAccessor = getViewableAccessor(
              targetNode,
              allNodes,
              selectedGroup
            );
            if (viewableAccessor) {
              viewableAccessor.meta.state = NODE_STATE.NODE_STATE_CONNECTED;
            }
          }
        });
      });

      const descendersIds = descenders.map((node) => node.data.number);
      // were the descenders are the target
      allNodes.forEachNode((sourceNode) => {
        if (
          sourceNode.data.next_nodes.some(
            (value) => -1 !== descendersIds.indexOf(value)
          )
        ) {
          const viewableAccessor = getViewableAccessor(
            sourceNode,
            allNodes,
            selectedGroup
          );
          if (viewableAccessor) {
            viewableAccessor.meta.state = NODE_STATE.NODE_STATE_CONNECTED;
          }
        }
      });

      selectedNode.meta.state = NODE_STATE.NODE_STATE_SELECTED;
    }
  }
}

const reducer = (state = getInitialState(), action) => {
  console.log(action.type);
  switch (action.type) {
    case BOT_USER_SESSION_LOGOUT: {
      return getInitialState();
    }
    case CLEAR_DESIGNER_DATA: {
      if (saveTimeout) clearTimeout(saveTimeout);
      window.localStorage.removeItem(`bot:${state.customerId}:${state.botId}`);
      return getInitialState();
    }
    case t.BOT_IS_UPLOADIND: {
      return Object.assign({}, state, { uploadingBot: true });
    }
    case t.BOT_WAS_UPLOADED: {
      saveBot(false, state);
      return Object.assign({}, state, {
        wasBotModified: false,
        uploadingBot: false,
      });
    }
    case t.BOT_WAS_NOT_UPLOADED: {
      return Object.assign({}, state, { uploadingBot: false });
    }
    case t.UPDATE_ZOOM_LEVEL: {
      return Object.assign({}, state, {
        zoomLevel: action.zoomLevel,
        zoomLevelMouseX: action.mouseX,
        zoomLevelMouseY: action.mouseY,
      });
    }

    case t.UPDATE_NODES: {
      //nodes,alignToRoot,selectedNodeId,selectedGroup,wasBotModified
      const selectedNodeId =
        action.selectedNodeId === undefined
          ? state.selectedNodeId
          : action.selectedNodeId;
      const selectedGroup =
        action.selectedGroup === undefined
          ? state.selectedGroup
          : action.selectedGroup;

      saveBot(action.nodes, state);

      setNodeState(action.nodes, selectedNodeId, selectedGroup);
      const edges = getEdgesToShow(action.nodes, selectedNodeId, selectedGroup);

      return Object.assign({}, state, {
        edges,
        nodes: action.nodes,
        alignToRoot: action.alignToRoot,
        selectedEmbedIndex: selectedNodeId ? state.selectedEmbedIndex : -1,
        selectedNodeId: selectedNodeId,
        selectedGroup: selectedGroup,
        wasBotModified: true,
        rightSideDrawerOpen: !!selectedNodeId,
      });
    }

    case t.NODE_SELECTED: {
      console.log(action);
      setNodeState(state.nodes, action.nodeId, state.selectedGroup);
      const edges = getEdgesToShow(
        state.nodes,
        action.nodeId,
        state.selectedGroup
      );

      return Object.assign({}, state, {
        edges,
        selectedNodeId: action.nodeId,
        rightSideDrawerOpen: !!action.nodeId,
        selectedEmbedIndex: -1,
      });
    }

    case t.OPEN_GROUP: {
      setNodeState(state.nodes, undefined, action.nodeId);
      const edges = getEdgesToShow(state.nodes, undefined, action.nodeId);

      return Object.assign({}, state, {
        edges,
        selectedNodeId: undefined,
        rightSideDrawerOpen: false,
        selectedGroup: action.nodeId,
        selectedEmbedIndex: -1,
      });
    }
    case t.EMBED_OPTION_SELECT: {
      return Object.assign({}, state, {
        selectedEmbedIndex: action.embedIndex,
      });
    }
    case t.EDGE_SELECTED: {
      return Object.assign({}, state, {
        selectedEdge: {
          nodeNumber: action.nodeNumber,
          edgeIndex: action.edgeIndex,
        },
      });
    }
    case t.EDGE_DESELECTED: {
      return Object.assign({}, state, { selectedEdge: null });
    }

    case t.UNSET_ALIGN_TO_ROOT:
      return Object.assign({}, state, { alignToRoot: false });
    case t.SET_GLOBAL_VARIABLES:
      return Object.assign({}, state, {
        globalVariables: action.globalVariables,
      });
    case t.SET_BOT_ID:
      return { ...state, customerId: action.customerId, botId: action.botId };
    case t.OPEN_LEFT_SIDE_DRAWER:
      return { ...state, leftSideDrawerOpen: true };
    case t.CLOSE_LEFT_SIDE_DRAWER:
      return { ...state, leftSideDrawerOpen: false };
    case t.OPEN_RIGHT_SIDE_DRAWER:
      return { ...state, rightSideDrawerOpen: true };
    case t.CLOSE_RIGHT_SIDE_DRAWER:
      return { ...state, rightSideDrawerOpen: false };
    case t.ADD_TO_CLIPBOARD:
      return {
        ...state,
        clipboard: action.updatedNodes,
        clipType: action.clipType,
        parentNodes: action.parentNodes,
      };
    case t.REMOVE_FROM_CLIPBOARD:
      return { ...state, clipboard: [] };
    default:
      return state;
  }
};

const undoableInitialState = {
  past: [],
  present: reducer(undefined, {}),
  future: [],
};

const getRelevantProperties = ({
  _dataRef,
  edges,
  selectedNodeId,
  zoomLevel,
  globalVariables,
  selectedGroup,
}) => {
  return {
    _dataRef,
    edges,
    selectedNodeId,
    zoomLevel,
    globalVariables,
    selectedGroup,
  };
};

// Return a reducer that handles undo and redo
export default (state = undoableInitialState, action) => {
  //_dataRef._root["entries"][0][1]._root["entries"]);
  const { past, present, future } = state;

  let pushToPast;
  let newPresent3;

  switch (action.type) {
    case t.UNDO: {
      if (!past.length) {
        return state;
      }
      const previous = past[past.length - 1];
      const newPresent = Object.assign({}, state.present, previous, {
        nodes: new NodeContainer(previous._dataRef),
      });
      const newPast = past.slice(0, past.length - 1);
      setNodeState(
        newPresent.nodes,
        newPresent.selectedNodeId,
        newPresent.selectedGroup
      );
      return {
        past: newPast,
        present: newPresent,
        future: [getRelevantProperties(present), ...future],
      };
    }
    case t.REDO: {
      if (!future.length) {
        return state;
      }
      const next = future[0];
      const newPresent2 = Object.assign({}, state.present, next, {
        nodes: new NodeContainer(next._dataRef),
      });
      const newFuture = future.slice(1);
      setNodeState(
        newPresent2.nodes,
        newPresent2.selectedNodeId,
        newPresent2.selectedGroup
      );
      return {
        past: [...past, getRelevantProperties(present)],
        present: newPresent2,
        future: newFuture,
      };
    }
    default:
      pushToPast = getRelevantProperties(present);

      // Delegate handling the action to the passed reducer
      newPresent3 = reducer(present, action);
      if (present === newPresent3) {
        return state;
      }

      newPresent3._dataRef = newPresent3.nodes
        ? newPresent3.nodes._dataRef
        : undefined;

      //save in the undo only the following action types
      if (
        [
          t.CLEAR_DESIGNER_DATA,
          t.OPEN_GROUP,
          t.SET_GLOBAL_VARIABLES,
          t.UPDATE_NODES,
        ].includes(action.type) &&
        !action.initialBotLoad
      ) {
        return {
          past: [...past, pushToPast],
          present: newPresent3,
          future: [],
        };
      } else if (action.initialBotLoad) {
        // clear all prev undo list
        return {
          past: [],
          present: newPresent3,
          future: [],
        };
      }
      return {
        past: past,
        present: newPresent3,
        future: [],
      };
  }
};
